export default [
  {
    key: 'requestedBy',
    label: 'field.requestedBy',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'employee',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
    cols: 12
  },
  {
    key: 'companyId',
    label: 'field.company',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'company',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'requestedDate',
    label: 'field.requestedDate',
    rules: 'required',
    type: 'date',
  },
  {
    key: 'expectedDate',
    label: 'field.expectedDate',
    rules: 'required',
    type: 'date',
  },
  {
    key: 'remark',
    label: 'field.remark',
    rules: 'max:300',
    type: 'textarea',
    cols: 12,
  },
  {
    key: 'itemId',
    label: 'field.item',
    type: 'nAsynSingleSelection',
    repository: 'item',
    selectionKey: 'id',
    selectionLabel: 'label',
    reduce: false,
    cols: 12,
  },
  {
    key: 'lines',
    label: 'field.requestedItem',
    cols: 12
  },
]
