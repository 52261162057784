<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col cols="12" class="text-right">
            <b-button
              variant="success"
              @click="exportPdf"
              class="mr-1"
              type="button"
              :disabled="loading"
            >
              {{ $t("button.exportPdf") }}
            </b-button>
            <b-button
              variant="success"
              @click="exportExcel"
              class="mr-1"
              type="button"
              :disabled="loading"
            >
              {{ $t("button.exportExcel") }}
            </b-button>
        </b-col>
      </b-row>
      <n-input-view
        :fields="fields"
        v-model="data"
        :initValue="initData"
      ></n-input-view>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInputView from "@/components/NInputView";
import FormInput from "./formInput";
import moment from "moment";

const purchaseReqeustReportRepository = Repository.get("purchaseRequestReport");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NFormConfirmation,
    NInputView,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {

      },
      initData: {},
      loading: false,
    };
  },
  mounted() {
    this.a = document.createElement("a");
    document.body.appendChild(this.a);
    this.a.style = "display: none";
    this.a.download = "daat.pdf";
    this.show();
  },
  methods: {
    
    show() {
      purchaseReqeustReportRepository.show(this.$route.params.id).then((response) => {
        console.log("###################");
        console.log(response);
        console.log("###################");
        if (response?.data?.data) {
          this.initData = {
            ...response.data.data,
          };
        }
      });
    },
    exportPdf() {
      this.loading = true;

      purchaseReqeustReportRepository.exportPdf(this.$route.params.id)
        .then((response) => {
          this.loading = false;
          
          let blob = new Blob([response.data], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `Export Report ${moment().format(
            "DD-MMM-YYYY"
          )}.pdf`;
          this.a.click();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    exportExcel() {
      this.loading = true;
      purchaseReqeustReportRepository.exportExcel(this.$route.params.id)
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }),
            url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `Stock Report ${moment().format(
            "DD-MMM-YYYY"
          )}.xlsx`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = [...FormInput];
    const permissionType = "purchase-request-report";

    return { fields, permissionType };
  },
};
</script>
